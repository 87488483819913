import React from "react"
import { graphql } from "gatsby"
import FAQCard from "../../components/FAQCard"
import SymptomsCard from "../../components/SymptomsCard"
import AdmissionGreetingCard from "../../components/AdmissionGreetingCard"
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import FAQList from "../../components/FAQList";


const FAQPage = ({ data, pageContext }) => {

  return (
    <Layout context={pageContext} openGraph={{
      url: pageContext.localizedUrls[pageContext.locale],
      title: pageContext.title,
      description: pageContext.description,
      images: [
        {
          url: `https://www.spinecentre.com.hk${data.firestore.page.content.headerImage}`
        }
      ]
    }}>
      <PageHeader
        title={data.firestore.page.content[pageContext.locale]['headerTitle']}
        imageUrl={data.firestore.page.content.headerImage}
      />
        <main className="my-12 flex flex-col lg:flex-row justify-evenly w-full">
          <FAQList/>
          <aside className="items-center flex flex-col space-y-6 lg:items-start lg:px-8">
            <AdmissionGreetingCard/>
            <SymptomsCard/>
            <FAQCard/>
        </aside>
      </main>
    </Layout>
  )
}

export default FAQPage

export const query = graphql`
  query FAQPageQuery($pageId: ID) {
    firestore {
      page(id: $pageId) {
        content
      }
    }
  }
`