import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useQueryParam, StringParam } from "use-query-params";
import { useSiteContext } from "../contexts/site";

function Question({ item }) {

  const [showAnswer, setShowAnswer] = useState(false)
  const [showQuestionId] = useQueryParam("show", StringParam);
  useEffect(() => {
    if(showQuestionId && showQuestionId===item.id) {
      setShowAnswer(true)
      document.getElementById(showQuestionId).scrollIntoView({ behavior: 'smooth' })
    }
  }, [showQuestionId])

  return (
    <div className="flex flex-col mb-8 transition-all ease-in-out duration-300" id={item.id} >
      <div className="flex items-center">
        <div className="w-1/12 mr-4">
          <span className="w-8 h-8 bg-coconut rounded-full flex items-center justify-center text-white">Q</span>
        </div>
        <button 
          className={`w-11/12 ${showAnswer?'text-salmon':'text-coconut'} font-semibold text-base lg:text-xl cursor-pointer text-left focus:outline-none`}
          onClick={() => setShowAnswer(!showAnswer)}>
          {item.question}
        </button>
      </div>
      {
        showAnswer && (
          <div className={`flex items-center ml:4 lg:ml-16 h-full transition-all duration-300 ease-in-out prose-sm lg:prose`}>
            <div className="w-8 h-8 bg-mango rounded-full flex items-center justify-center text-white">A</div>
            <p className="ml-4 w-full lg:w-3/4" dangerouslySetInnerHTML={{__html: `<div>${item.answer}</div>`}}></p>
          </div>
        )
      }
    </div>
  )
}

export default function FAQList() {
  const { locale } = useSiteContext();
  const data = useStaticQuery(graphql`
    query FAQList {
      firestore {
        ch: faqs(locale: "ch") {
          id
          answer
          question
        }
        en: faqs(locale: "en") {
          id
          answer
          question
        }
      }
    }
  `)

  return (
    <div className="mx-8">
      {
        data.firestore[locale].map(item => (
          <Question key={item.id} item={item}/>
        ))
      }
    </div>
  )
}